'use strict';

/**
 * ヘッダメニュー、ブランドバーのスクロール時の動作
 */
function readyInit() {
    let beforePos = 0; // スクロールの値の比較用の設定
    let elemTop = 120; // アニメーション開始位置
    let areaTop = 0;

    const resetAreaTop = function () {
        const submenu = document.getElementById('submenu');
        if (submenu) {
            const offset = $(submenu).offset();
            if ($(window).width() < 768) {
                areaTop = offset.top - 45;
            } else {
                areaTop = offset.top - 75;
            }
        }
    };
    resetAreaTop();

    // リサイズ監視
    $(window).on('resize', () => {
        resetAreaTop();
    });

    // スクロール監視
    $(window).on('scroll', () => {
        const scroll = $(window).scrollTop();

        $('.effect').each(function () {
            // エフェクト監視
            const effectTop = $(this).offset().top;
            const wHeight = $(window).height();
            if (effectTop + 100 < wHeight + scroll) {
                $(this).addClass('fadeUp');
            }
        });

        const $submenu = $('#submenu');
        if (scroll > areaTop) {
            $submenu.addClass('fixed');
            $submenu.removeClass('active');
        } else {
            $submenu.addClass('active');
            $submenu.removeClass('fixed');
        }

        const $brand = $('#brand');
        const $header = $('#header');
        const $taneyaImg = $('#taneya-img');
        const $tnychImg = $('#tnych-img');
        const $harieImg = $('#harie-img');

        const pos = beforePos;
        beforePos = scroll; // 現在のスクロール値を比較用のbeforePosに格納

        if (scroll === 0) {
            $header.removeClass('UpMove').addClass('DownMove');
            $brand.addClass('BrandTop').removeClass('DownBrand DownBrandMini');
            $taneyaImg.addClass('ImgTop').removeClass('DownImg');
            $harieImg.addClass('ImgTop').removeClass('DownImg');
            $tnychImg.addClass('ImgTop').removeClass('DownImg');
            return;
        }
        if (scroll === pos) {
            return;
        }
        if (scroll < pos) {
            $header.removeClass('UpMove').addClass('DownMove');
            if (elemTop < scroll) {
                // 上スクロール
                $brand.addClass('UpBrand').removeClass('DownBrand');
                $taneyaImg.removeClass('ImgInit ImgTop');
                $tnychImg.removeClass('ImgInit ImgTop');
                $harieImg.removeClass('ImgInit ImgTop');
            } else {
                $brand.addClass('BrandTop').removeClass('DownBrand');
                $taneyaImg.addClass('ImgTop').removeClass('DownImg');
                $tnychImg.addClass('ImgTop').removeClass('DownImg');
                $harieImg.addClass('ImgTop').removeClass('DownImg');
            }
            return;
        }
        if (elemTop < scroll) {
            // 下スクロール
            $header.addClass('UpMove').removeClass('DownMove');
            if ($brand.hasClass('DownBrandMini')) {
                $brand.removeClass('BrandInit BrandTop UpBrand');
                $taneyaImg.addClass('DownImg').removeClass('ImgInit ImgTop');
                $tnychImg.addClass('DownImg').removeClass('ImgInit ImgTop');
                $harieImg.addClass('DownImg').removeClass('ImgInit ImgTop');
            } else {
                $brand
                    .addClass('DownBrand DownBrandMini')
                    .removeClass('BrandInit BrandTop UpBrand');
                $taneyaImg.addClass('DownImg').removeClass('ImgInit ImgTop');
                $tnychImg.addClass('DownImg').removeClass('ImgInit ImgTop');
                $harieImg.addClass('DownImg').removeClass('ImgInit ImgTop');
            }
        }
    });
}
readyInit();

//
// Intersection Observerの設定
//
const intersectionOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
};

/**
 *  交差したときにFadeInエフェクト表示
 *
 * @param {*} entries Html部品
 */
function intersectfadeIn(entries) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            $(entry.target).addClass('fadeIn');
            observerfadeIn.unobserve(entry.target);
        }
    });
}
// FadeInオブザーバー
const observerfadeIn = new IntersectionObserver(intersectfadeIn, intersectionOptions);

// エフェクト表示
$(function () {
    // fadeIn
    const targetsfadeIn = document.querySelectorAll('.fadeInEffect');
    // 対象の要素をそれぞれ監視する
    targetsfadeIn.forEach((target) => {
        observerfadeIn.observe(target);
    });

    // fadeUp
    const observerfadeUp = new IntersectionObserver(intersectfadeUp, intersectionOptions);
    const targetsfadeUp = document.querySelectorAll('.fadeUpEffect');
    // 対象の要素をそれぞれ監視する
    targetsfadeUp.forEach((target) => {
        observerfadeUp.observe(target);
    });
    // 交差したときにエフェクト表示
    function intersectfadeUp(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                $(entry.target).addClass('fadeUp');
                observerfadeUp.unobserve(entry.target);
            }
        });
    }

    // fadeDown
    const observerfadeDown = new IntersectionObserver(intersectfadeDown, intersectionOptions);
    const targetsfadeDown = document.querySelectorAll('.fadeDownEffect');
    // 対象の要素をそれぞれ監視する
    targetsfadeDown.forEach((target) => {
        observerfadeDown.observe(target);
    });
    // 交差したときにエフェクト表示
    function intersectfadeDown(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                $(entry.target).addClass('fadeDown');
                observerfadeDown.unobserve(entry.target);
            }
        });
    }

    // fadeLeft
    const observerfadeLeft = new IntersectionObserver(intersectfadeLeft, intersectionOptions);
    const targetsfadeLeft = document.querySelectorAll('.fadeLeftEffect');
    // 対象の要素をそれぞれ監視する
    targetsfadeLeft.forEach((target) => {
        observerfadeLeft.observe(target);
    });
    // 交差したときにエフェクト表示
    function intersectfadeLeft(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                $(entry.target).addClass('fadeLeft');
                observerfadeLeft.unobserve(entry.target);
            }
        });
    }

    // fadeRight
    const observerfadeRight = new IntersectionObserver(intersectfadeRight, intersectionOptions);
    const targetsfadeRight = document.querySelectorAll('.fadeRightEffect');
    // 対象の要素をそれぞれ監視する
    targetsfadeRight.forEach((target) => {
        observerfadeRight.observe(target);
    });
    // 交差したときにエフェクト表示
    function intersectfadeRight(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                $(entry.target).addClass('fadeRight');
                observerfadeRight.unobserve(entry.target);
            }
        });
    }
});

//
// モバイルメニューアクセシビリティ
//
$(function () {
    $('#js-hamburger').on('click', function () {
        $('.gnavi_inner_sp').toggleClass('is-drawer-active');
        if ($(this).attr('aria-expanded') === 'false') {
            $(this).attr('aria-expanded', true);
            $('#js-drawer').attr('area-hidden', false);
        } else {
            $(this).attr('aria-expanded', false);
            $('#js-drawer').attr('area-hidden', true);
        }
    });
});

//
// タブ初期選択
//
window.addEventListener('DOMContentLoaded', function () {
    // #(ハッシュ)指定されたタブを表示する
    const hash = location.hash;
    if (hash) {
        $('.nav-tabs a[href="' + hash + '"]').tab('show');
    }
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        e.target; // newly activated tab
        e.relatedTarget; // previous active tab
    });
});

module.exports = {
    /**
     * FadeIn監視対象の追加。
     *
     * 「もっと見る」ボタンによりAjaxを利用して表示対象を追加した際に、`FadeIn`の監視対象を追加する。
     * 引数のセレクターや検索範囲を指定しない場合は、`.fadeInEffect`に該当するエレメントを全件処理するため注意すること。
     *
     * @param {string} [selector='.fadeInEffect'] - 追加対象を検索するセレクター 例：`'._unobserved-fade-in'`
     * @param {(jQuery|null|undefined)} $parent - 追加対象を検索する範囲 例：`$('#productGrid')`
     * @returns {jQuery} 追加対象を表すjQueryオブジェクト 例：`$('.fadeInEffect')`
     * - 呼び出し元で`_unobserved-fade-in`といったマーカーを削除する際に利用することができる。
     * @example
     * const tnyMain = require('../tny_main');
     * const $newItemGrid = $('#newItemGrid');
     * const $newTiles = tnyMain.addObserverFadeIn('._unobserved-fade-in', $newItemGrid);
     * $newTiles.removeClass('_unobserved-fade-in');
     */
    addObserverFadeIn: function (selector = '.fadeInEffect', $parent) {
        const $fadeInEffects = $(selector, $parent);
        // 対象の要素をそれぞれ監視する
        $fadeInEffects.each(function (index, elem) {
            observerfadeIn.observe(elem);
        });
        return $fadeInEffects;
    },
};
